import { loadManifest } from '@angular-architects/module-federation';
import '@angular/localize/init';  //Todo - check if RTSE can share it without importing it in MNRE
// import('./bootstrap')
// 	.catch(err => console.error(err));



loadManifest("assets/mf.manifest.json")
	.catch(err => console.error(err))
	.then(_ => import('./bootstrap'))
	.catch(err => console.error(err));